// src/TickerSearch.js

import React, { useState, useEffect } from 'react';
import './TickerSearch.css';
import companyList from './sec_companies.json';
import api from './api';

import News from './components/News';
import MarketIndices from './components/MarketIndices';
import Fundamentals from './components/Fundamentals';
import Search from './components/Search';
import Sidebar from './components/Sidebar';
import SearchForm from './components/SearchForm';
import Most from './components/Most';
import Auth from './components/Auth';
import Footer from './components/Footer';
import AdminDashboard from './components/AdminDashboard';
import Thesis from './components/Thesis';

function TickerSearch() {
  // State variables to manage application state
  const [ticker, setTicker] = useState('');
  const [currentTicker, setCurrentTicker] = useState('');
  const [companySearch, setCompanySearch] = useState('');
  const [error, setError] = useState('');
  const [searches, setSearches] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]); // Filtered list for dropdown
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // New state for highlighted index
  const [isFundamentalsLoading, setIsFundamentalsLoading] = useState(true);
  const [isMarketIndicesLoading, setIsMarketIndicesLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoadingAuth, setIsLoadingAuth] = useState(true); 
  const [thesisData, setThesisData] = useState(null);
  const [isThesisLoading, setIsThesisLoading] = useState(false);

  const fetchSearches = async () => {
    try {
      const response = await api.get('/get_searches');
      setSearches(response.data);
    } catch (err) {
      console.error('Failed to fetch search history:', err);
    }
  };

  useEffect(() => {
    setFilteredCompanies(companyList); // Load company data
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        console.log('Checking authentication...');
        const response = await api.get('/check_auth');
        console.log('Auth response:', response.data);
        if (response.data.authenticated) {
          setIsAuthenticated(response.data.username);
          console.log('User authenticated:', response.data.username);
        } else {
          setIsAuthenticated(false);
          console.log('User not authenticated');
        }
      } catch (err) {
        console.error('Auth check failed:', err);
        setIsAuthenticated(false);
      } finally {
        setIsLoadingAuth(false); // Set loading to false after check
      }
    };
    
    checkAuth();
  }, []); // Only runs on mount

  useEffect(() => {
    console.log('Authentication state changed:', isAuthenticated);
    if (isAuthenticated) {  // Only fetch searches when authenticated
      console.log('Fetching searches for authenticated user...');
      fetchSearches();
    } else {
      setSearches([]); // Clear searches when not authenticated
    }
  }, [isAuthenticated]); // Runs when auth status changes

  // Handle form submission to fetch all company info components
  const handleSubmitSearch = async (tickerValue) => {
    setThesisData(null);
    setCompanySearch('');
    setError('');
    setFilteredCompanies([]);

    const isValidTicker = Object.values(companyList).some(
      company => company.ticker.toLowerCase() === tickerValue.toLowerCase()
    );

    if (!isValidTicker) {
      setError('Please enter a valid ticker symbol from the dropdown list.');
      return;
    }

    // Check if the ticker is already in the search history
    const existingSearch = searches.find(
      search => search.ticker.toLowerCase() === tickerValue.toLowerCase()
    );

    if (existingSearch) {
      setCompanySearch(existingSearch.info);
      setCurrentTicker(tickerValue);
      return;
    }

    setCurrentTicker(tickerValue);
    setIsLoading(true);

    try {
      // Get company info
      const infoResponse = await api.post('/stock_search', { ticker: tickerValue });
      setCompanySearch(infoResponse.data.info);
      fetchSearches();
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle click on a previous search to display its info
  const handleSearchClick = async (selectedTicker) => {
    setThesisData(null);
    const existingSearch = searches.find(search => search.ticker === selectedTicker);
    if (existingSearch) {
      setTicker(selectedTicker);
      setCurrentTicker(selectedTicker);
      setCompanySearch(existingSearch.info);
      setError('');
    }
  };

  const handleSearchFromAuth = async (tickerValue) => {
    setThesisData(null);
    try {
      const response = await api.post('/login', {username: "test", password: "test"});
      
      if (response.data.message) {
        setIsAuthenticated(response.data.username);
      }
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
    }
    await handleSubmitSearch(tickerValue);
  };

  // Handle deletion of a search entry
  const handleDelete = async (tickerToDelete) => {
    try {
      setSearches(searches.filter(search => search.ticker !== tickerToDelete));
      await api.delete('/delete_search', { data: { ticker: tickerToDelete } });
      fetchSearches();
    } catch (err) {
      console.error('Failed to delete search:', err);
    }
  };

  // Regenerate company info by refetching from the server
  const regenerateInfo = async () => {
    setIsLoading(true);
    setError('');

    try {
      // Delete the original search entry
      await handleDelete(currentTicker.toUpperCase());

      const response = await api.post('/stock_search', { ticker: currentTicker, regenerate: true });
      setCompanySearch(response.data.info);
      fetchSearches();
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to clear search input, dropdown, and company info
  const clearSearch = async () => {
    setThesisData(null);
    setTicker('');
    setFilteredCompanies([]);
    setCompanySearch('');
    setError('');
    setHighlightedIndex(-1);
    setCurrentTicker('');
  };

  const getCompanyTitle = () => {
    return Object.values(companyList).find(
      company => company.ticker.toUpperCase() === currentTicker.toUpperCase()
    )?.title || 'Company';
  };

  const handleLogout = async () => {
    setThesisData(null);
    setTicker('');
    setFilteredCompanies([]);
    setCompanySearch('');
    setError('');
    setHighlightedIndex(-1);
    setCurrentTicker('');

    try {
      const response = await api.post('/logout');
      if (response.data.message) {
        // Clear any client-side storage
        localStorage.clear();
        sessionStorage.clear();
        
        // Clear cookies using JavaScript
        document.cookie.split(";").forEach(function(c) { 
          document.cookie = c.replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); 
        });
        
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const generateThesis = async (tickerValue) => {
    setThesisData(null);
    setError('');
    
    const isValidTicker = Object.values(companyList).some(
      company => company.ticker.toLowerCase() === tickerValue.toLowerCase()
    );

    if (!isValidTicker) {
      setError('Please enter a valid ticker symbol from the dropdown list.');
      return;
    }

    setIsThesisLoading(true);

    try {
      setThesisData(null);
      const response = await api.post('/generate_thesis', { ticker: tickerValue });
      setThesisData(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to generate thesis');
    } finally {
      setIsThesisLoading(false);
    }
  };

  const regenerateThesis = async (tickerValue) => {
    setThesisData(null);
    setError('');
    
    const isValidTicker = Object.values(companyList).some(
      company => company.ticker.toLowerCase() === tickerValue.toLowerCase()
    );

    if (!isValidTicker) {
      setError('Please enter a valid ticker symbol from the dropdown list.');
      return;
    }

    setIsThesisLoading(true);

    try {
      setThesisData(null);
      const response = await api.post('/generate_thesis', { ticker: tickerValue, regenerate: true });
      setThesisData(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to generate thesis');
    } finally {
      setIsThesisLoading(false);
    }
  };

  // Render loading spinner if auth check is in progress
  if (isLoadingAuth) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="app-container">
      {!isAuthenticated ? (
        <Auth onAuthChange={setIsAuthenticated} handleSearchFromAuth={handleSearchFromAuth} />
      ) : isAuthenticated === 'admin' ? (
        <AdminDashboard onLogout={handleLogout} />
      ) : (
        <>
          <Sidebar 
            searches={searches}
            handleSearchClick={handleSearchClick}
            handleDelete={handleDelete}
            clearSearch={clearSearch}
            isLoading={isLoading || isThesisLoading}
          />
          <main className="main-content">
            <h1 className="homepage-heading">What's On Your Watchlist Today?</h1>
            <SearchForm 
              isLoading={isLoading || isThesisLoading}
              companyList={companyList}
              handleSubmitSearch={handleSubmitSearch}
              onTickerSelect={(selectedTicker) => {
                setTicker(selectedTicker);
              }}
            />

            {!currentTicker && (
              <>
                <MarketIndices setIsMarketIndicesLoading={setIsMarketIndicesLoading} />
                {!isMarketIndicesLoading && (
                  <Most handleSubmitSearch={handleSubmitSearch} />
                )}
              </>
            )}

            {error && <div className="error-message">{error}</div>}

            {currentTicker && (
              <Fundamentals 
                key={`fundamentals-${currentTicker}`}
                currentTicker={currentTicker}
                getCompanyTitle={getCompanyTitle}
                setIsFundamentalsLoading={setIsFundamentalsLoading}
              />
            )}

            {!isFundamentalsLoading && (
              <>
                <Search 
                  key={`search-${currentTicker}`}
                  companySearch={companySearch}
                  currentTicker={currentTicker}
                  getCompanyTitle={getCompanyTitle}
                  regenerateInfo={regenerateInfo}
                  isLoading={isLoading}
                />
                <Thesis
                  key={`thesis-${currentTicker}`}
                  thesisData={thesisData}
                  currentTicker={currentTicker}
                  getCompanyTitle={getCompanyTitle}
                  isThesisLoading={isThesisLoading}
                  generateThesis={generateThesis}
                  regenerateThesis={regenerateThesis}
                />
              </>
            )}
          </main>
          <News 
            key={`news-${currentTicker}`}
            currentTicker={currentTicker}
            getCompanyTitle={getCompanyTitle}
          />
          <Footer 
            isAuthenticated={isAuthenticated}
            onLogout={handleLogout}
            username={isAuthenticated}
          />
        </>
      )}
    </div>
  );
}

export default TickerSearch;
